var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(_vm.articleCategory)?[_c('h1',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.articleCategory.names[0].name))]),(_vm.articleCategory.image)?_c('v-img',{attrs:{"src":_vm.articleCategory.image.src,"alt":_vm.articleCategory.image.alt,"height":"450px","contain":""}}):_vm._e(),_c('ArticleList',{attrs:{"articles":_vm.articleCategory.articles,"show-properties":""},on:{"click":_vm.articleClicked},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var article = ref.article;
return [_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.articleQuantities[article.id] !== undefined),expression:"articleQuantities[article.id] !== undefined"}]},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('shop.cart.quantity'),"type":"number","min":"1","outlined":"","hide-details":"","dense":"","rules":[function (v) { return !!v; }, function (v) { return v && v > 0; }]},model:{value:(
                                    _vm.articleQuantities[article.id]
                                ),callback:function ($$v) {_vm.$set(_vm.articleQuantities, article.id, _vm._n($$v))},expression:"\n                                    articleQuantities[article.id]\n                                "}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-btn',{attrs:{"contain":"","color":"primary","disabled":!_vm.articleQuantities[article.id] ||
                                    _vm.articleQuantities[article.id] <= 0},on:{"click":function($event){return _vm.addArticleToCart(
                                        article,
                                        _vm.articleQuantities[article.id]
                                    )}}},[_vm._v(_vm._s(_vm.$t("shop.cart.addItem"))+" ")])],1)],1)],1)]}}],null,false,440489598)})]:_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }